<template>
  <date-filters
    v-model="computedDate"
    low
    :label="$t('t.Date')"
    class="value"
    ref="input"
  />
</template>

<script>
export default {
  components: {
    DateFilters: () => import('../../date-filters')
  },
  computed: {
    computedDate: {
      get () { return this.dataDate },
      set (v) {
        this.dataDate = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'scalar', to: this.dataDate }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataDate: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataDate = v?.to ? this.lodash.cloneDeep(v.to) : null
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
